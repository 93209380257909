@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
/*Theme Colors*/
/**
 * Table Of Content
 *
 * 	1. Color system
 *	2. Options
 *	3. Body
 *	4. Typography
 *	5. Breadcrumbs
 *	6. Cards
 *	7. Dropdowns
 *	8. Buttons
 *	9. Typography
 *	10. Progress bars
 *	11. Tables
 *	12. Forms
 *	14. Component
 */
/*******************
 Our income
*******************/
.o-income .c3 line, .o-income .c3 path {
  stroke: #e9ecef; }

aside .scroll-sidebar {
    overflow-y: scroll;
}

.scroll-sidebar::-webkit-scrollbar {
    display: none;
}

.companySelect {
  border: none;
  background: #037de7;
  outline: none;
  color: #fff;
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
}

.mini-sidebar .companySelect {
  display: none;
}

.notVerifyUser {
  position: fixed;
  min-height: 100vh;
  width: 100%;
  background: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  z-index: 2;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}